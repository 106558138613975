// useGoogleAds.js
import { useEffect } from 'react';

const useGoogleAds = () => {
  const trackPurchase = (transaction_id, value, currency) => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-11414639088/TY5lCOmy34wZEPCj9sIq',
        transaction_id: transaction_id,
        value: value,
        currency: currency,
      });
    }
  };

  const trackSignup = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-11414639088/3vl_CMyb6YwZEPCj9sIq',
      });
    }
  };

  useEffect(() => {
    window.trackPurchase = trackPurchase; // Expose the function globally
    window.trackSignup = trackSignup; // Expose the function globally

    // Clean up function to remove the function from window object
    return () => {
      delete window.trackPurchase;
      delete window.trackSignup;
    };
  }, []);

  return {
    trackPurchase,
    trackSignup,
  };
};

export default useGoogleAds;
