import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useAuth } from '@/contexts/AuthContext';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  process.env.NODE_ENV === 'production'
    ? process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
    : process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_TEST_KEY,
);
const RedirectingModal = dynamic(() =>
  import('@/components/modals/RedirectingModal'),
);

const frequencies = [
  { value: 'month', label: 'Monthly', priceSuffix: '/month' },
  { value: 'year', label: 'Annually', priceSuffix: '/year' },
];
const tiers = [
  {
    name: 'Free',
    id: 'Free',
    price: '$0',
    description: 'The initial plan for exploring the product.',
    features: [
      'Limited Access based on Credits',
      'Access to Medical Chat Chatbot',
      'Access to ChatGPT 3.5 Chatbot',
      'Access to AI Diagnose',
      '6 Message Credits per Month',
    ],
    featured: false,
    cta: 'Get Started',
  },
  {
    name: 'Premium',
    id: 'Premium',
    description:
      'The unlocked plan provides unlimited full access to all features.',
    features: [
      'Unlimited Access to Medical Chat Chatbot',
      'Unlimited Access to ChatGPT 3.5 Chatbot',
      'Unlimited Access to ChatGPT 4.0/ChatGPT 4o Chatbot',
      'Unlimited Access to AI Diagnose',
      '100 times of Google Search + ChatGPT 3.5',
      'Import & Export data',
    ],
    featured: false,
    cta: 'Upgrade',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Pricing({ pricesMap }) {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const { t } = useTranslation();
  const [currentPlan, setCurrentPlan] = useState('');
  const { currentUser } = useAuth();
  const [openRedirect, setOpenRedirect] = useState(false);
  useEffect(() => {
    if (!currentUser || currentPlan) return;
    const getPlan = () => {
      const subscriptions = currentUser.subscriptions;
      if (!subscriptions) {
        return 'Free';
      }
      if (subscriptions.includes('Premium')) {
        return 'Premium';
      } else {
        return 'Free';
      }
    };
    setCurrentPlan(getPlan());
  }, [currentUser, currentPlan]);

  const yourStatus = (
    <>
      <div className="rounded-md bg-green-100 py-2 px-3 mt-6 font-semibold text-green-700 text-center">
        {t('Unlocked')}
      </div>
    </>
  );
  const handleSubscription = async (e) => {
    if (!currentUser) {
      setOpenRedirect(true);
      return;
    }
    const priceId = pricesMap[frequency.value].id;
    const { createCheckout } = await import('@/libs/request');
    const sessionId = await createCheckout({
      priceId,
      email: currentUser.email,
    });
    const stripe = await stripePromise;
    stripe.redirectToCheckout({ sessionId });
  };

  function closeRedirectModal() {
    setOpenRedirect(false);
  }

  return (
    <div
      id="pricing"
      className="bg-white sm:py-8 lg:px-8 lg:py-10 relative bg-transparent"
    >
      <Image
        alt="Pricing Image background"
        src="/images/background-pricing.webp"
        width={1800}
        height={1800}
        sizes="(max-width: 480px) 100vw, (max-width: 768px) 100vw, 100vw"
        decoding="async"
        data-nimg="1"
        className="absolute top-0 left-0 -z-10"
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-2xl md:text-3xl lg:text-4xl  font-bold tracking-tight text-gray-900">
            {t('price_header')}
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          {t(
            'Receive a complimentary two-month subscription when you opt for an annual plan!',
          )}
        </p>
        <div className="mt-6 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="shadow-md grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1',
                  )
                }
              >
                <span>{t(option.label)}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200',
                'rounded-3xl p-8 ring-1 xl:p-10 shadow-lg',
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-lg font-semibold leading-8',
                )}
              >
                {tier.name}
              </h3>
              <p
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-4 text-sm leading-6',
                )}
              >
                {t(tier.description)}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? 'text-white' : 'text-gray-900',
                    'text-4xl font-bold tracking-tight',
                  )}
                >
                  {typeof tier.price === 'string'
                    ? tier.price
                    : `$${pricesMap[frequency.value].unit_amount / 100}`}
                </span>
                <span
                  className={classNames(
                    tier.featured ? 'text-gray-300' : 'text-gray-600',
                    'text-sm font-semibold leading-6',
                  )}
                >
                  {t(frequency.priceSuffix)}
                </span>
              </p>

              {tier.id === 'Free' ? (
                currentUser ? (
                  yourStatus
                ) : (
                  <button
                    onClick={handleSubscription}
                    aria-describedby={tier.id}
                    id={tier.id}
                    className={classNames(
                      'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600',
                      'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full',
                    )}
                  >
                    {t(tier.cta)}
                  </button>
                )
              ) : currentPlan === tier.id ? (
                yourStatus
              ) : (
                <button
                  onClick={handleSubscription}
                  aria-describedby={tier.id}
                  id={tier.id}
                  className={classNames(
                    'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600',
                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full',
                  )}
                >
                  {t(tier.cta)}
                </button>
              )}
              <ul
                role="list"
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-8 space-y-3 text-sm leading-6 xl:mt-10',
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className={classNames(
                        tier.featured ? 'text-white' : 'text-indigo-600',
                        'h-6 w-5 flex-none',
                      )}
                      aria-hidden="true"
                    />
                    {t(feature)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      {openRedirect && (
        <RedirectingModal
          openRedirect={openRedirect}
          closeModal={closeRedirectModal}
          msg="Please Sign In First to Take Any Action"
          url="/signin"
        />
      )}
    </div>
  );
}
