import React, { useContext, useEffect, useState } from 'react';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

function generateUserId() {
  return new Promise(async (resolve) => {
    const CookiesModule = await import('js-cookie');
    const Cookies = CookiesModule.default;
    const userId = Cookies.get('ga_user_id');
    if (userId) {
      Cookies.set('ga_user_id', userId, { expires: 30 });
      resolve(userId);
    } else {
      const Fingerprint2Module = await import('fingerprintjs2');
      const Fingerprint2 = Fingerprint2Module.default;
      Fingerprint2.getV18({}, async (value) => {
        const fingerprint = Fingerprint2.x64hash128(value, 31);
        const response = await fetch(
          `/api/v1/auth/generate-user-id?token=${fingerprint}`,
        );
        const data = await response.json();
        const newUserId = data.newUserId;
        Cookies.set('ga_user_id', newUserId, { expires: 30 });
        resolve(newUserId);
      });
    }
  });
}

export function AuthProvider({ children, user = null }) {
  const [currentUser, setCurrentUser] = useState(user);
  const [credits, setCredits] = useState(user ? user.freeCredits : 0);
  const [googleCredits, setGoogleCredits] = useState(
    user ? user.googleCredits : 0,
  );
  useEffect(() => {
    generateUserId().then(async (userId) => {
      const ReactGAModule = await import('react-ga');
      const ReactGA = ReactGAModule.default;
      ReactGA.initialize('G-8DXX0XY6QZ');
      ReactGA.set({ userId });
      ReactGA.pageview(window.location.pathname); // Track initial pageview
    });
  }, []);
  const value = {
    currentUser,
    setCurrentUser,
    credits,
    setCredits,
    googleCredits,
    setGoogleCredits,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
