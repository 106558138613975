import { useEffect, useState } from 'react';

const PREFIX = 'medical-chat-';

export default function useSessionStorage(key, initialValue) {
  const prefixedKey = PREFIX + key;
  const [value, setValue] = useState(() => {
    try {
      if (window.sessionStorage) {
        const jsonValue = window.sessionStorage.getItem(prefixedKey);
        if (jsonValue != null && jsonValue !== 'undefined') {
          return JSON.parse(jsonValue);
        }
      }
      if (typeof initialValue === 'function') {
        return initialValue();
      } else {
        return initialValue;
      }
    } catch (err) {
      if (typeof initialValue === 'function') {
        return initialValue();
      } else {
        return initialValue;
      }
    }
  });

  useEffect(() => {
    try {
      if (window.sessionStorage) {
        window.sessionStorage.setItem(prefixedKey, JSON.stringify(value));
      }
    } catch (err) {}
  }, [prefixedKey, value]);

  return [value, setValue];
}
