// Banner.jsx
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';

const ResponsiveSVG = () => {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 418 42"
      className="absolute fill-blue-300/70 top-2/3 w-full h-auto"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
    </svg>
  );
};

const HomePageButton = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center my-2 flex gap-2">
      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
      <a
        href="/chat"
        style={{ boxShadow: '0px 7px 10px rgba(0, 0, 0, 0.5)' }}
        className="block hover:translate-y-[-2px] block bg-chatdata-gradient btn font-semibold leading-7 lg:p-3 mb-2 p-2 rounded-md text-white text-base"
      >
        {t('chatbot')}
      </a>
      <Link
        href="/diagnose"
        style={{ boxShadow: '0px 7px 10px rgba(0, 0, 0, 0.5)' }}
        className="block hover:translate-y-[-2px] bg-chatdata-gradient btn font-semibold leading-7 lg:p-3 mb-2 p-2 rounded-md text-white text-base"
      >
        {t('diagnose_btn')}
      </Link>
    </div>
  );
};

const BannerSwiper = () => {
  // Assuming `t` is a function for translation
  const { t } = useTranslation();
  return (
    <>
      <div className="banner-wrapper">
        <div className="mx-auto container px-2 pt-6">
          <div className="flex gap-2 items-center justify-center h-30 text-lg leading-27 pt-4">
            <Image
              alt="announcement"
              src="/new.svg"
              width={41}
              height={20}
              className="project-text-tag"
              priority
            />
            <Link href="/evaluate" className="flex items-center">
              <span className="text-xs sm:text-sm lg:text-base my-auto">
                Medical Chat stands out as the most accurate medical
                question-answering system based on both USMLE and MedQA
                performance evaluation
              </span>
              <span className="block font-semibold text-gray-500 my-auto">
                <ChevronRightIcon className="w-4 h-4 inline-block" />
              </span>
            </Link>
          </div>
          <div className="mx-auto mb-4 max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
            <span className="relative whitespace-nowrap text-blue-600">
              <ResponsiveSVG />
              <h1 className="relative font-black">{t('home_title')}</h1>
            </span>
          </div>

          <h2 className="banner-title">
            <Trans
              i18nKey="home_title_2"
              values={{ target: t('Human/Veterinary') }}
              components={[
                <span
                  key="red-strong"
                  className="bg-chatdata-gradient-text bg-clip-text text-transparent"
                />,
              ]}
            ></Trans>
          </h2>
          <span className="banner-subtitle text-sm md:text-base lg:text-lg">
            {t('home_sub_title')}
          </span>
          <HomePageButton />
          <div className="text-center feature-image">
            <div className="mx-auto aspect-ratio-placeholder">
              <Image
                className="mx-auto absolute top-0 left-0"
                alt="medical chatbot demo"
                src="/demos/chatbot_en.webp"
                sizes="(max-width: 480px) 100vw, (max-width: 768px) 100vw, 100vw"
                decoding="async"
                width={1800}
                height={1800}
                priority
                data-nimg="1"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerSwiper;
