import React, { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { t } from 'i18next';

const Video = dynamic(() => import('./Video'));

const VerticalVideoList = ({
  icon,
  title,
  subtitle,
  videos,
  messages,
  className = '',
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [activeClass, setActiveClass] = useState('');
  const [nextClass, setNextClass] = useState('');

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const nIdx = parseInt(e.currentTarget.id);
      if (nIdx === selectedIndex) {
        return;
      }

      setNextIndex(nIdx);
      let nextClassAdd = 'carousel-item-next';
      if (nIdx < selectedIndex) {
        nextClassAdd = 'carousel-item-prev';
      }
      setNextClass(nextClassAdd);
    },
    [selectedIndex],
  );

  useEffect(() => {
    if (nextIndex == selectedIndex) {
      return;
    }
    let bothClassAdd = 'carousel-item-start';
    if (nextIndex < selectedIndex) {
      bothClassAdd = 'carousel-item-end';
    }

    setNextClass((prev) => `${prev} ${bothClassAdd}`);
    setActiveClass((prev) => `${prev} ${bothClassAdd}`);

    const timeout = setTimeout(() => {
      setNextClass('');
      setActiveClass('');
      setSelectedIndex(nextIndex);
    }, 600);

    return () => {
      setNextClass('');
      setActiveClass('');
      setSelectedIndex(nextIndex);
      clearTimeout(timeout);
    };
  }, [nextIndex, selectedIndex]);
  return (
    <>
      <div className="feature-content col-lg-4 col-md-12 mb-2">
        <div className="content-title text-2xl md:text-3xl lg:text-4xl font-bold">
          {icon}
          <h3 className="title-text">{title}</h3>
        </div>
        <p className="content-subtitle text-base sm:text-lg md:text-xl">
          {subtitle}
        </p>
        <ol className="desc-list text-sm sm:text-base md:text-lg">
          {messages.map((message, index) => (
            <li
              id={index}
              key={index}
              onClick={handleClick}
              className={`desc-item ${
                index == nextIndex ? 'list-item-active' : ''
              }`}
            >
              <div
                id={index}
                className="list-item-selected desc-item-selected"
              ></div>
              <p id={index} className="list-item-text desc-item-text">
                {t(message)}
              </p>
            </li>
          ))}
        </ol>
      </div>

      <div className="feature-video shadow-lg bg-white rounded-md p-1">
        <div className="carousel-vertically carousel slide">
          <div className="carousel-inner">
            {videos.map((video, index) => (
              <div
                id={index}
                key={index}
                className={clsx('carousel-item', {
                  [activeClass]: index === selectedIndex,
                  [nextClass]: index === nextIndex,
                  active: index === selectedIndex,
                })}
              >
                <Video
                  className={className}
                  large={video.large}
                  w_1080={video.w_1080}
                  w_768={video.w_768}
                  w_480={video.w_480}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerticalVideoList;
